import React, { useState, useEffect } from 'react';
import { TextInput, Button, InlineNotification, Dropdown, Accordion, AccordionItem } from '@carbon/react';
import { useAuth } from '../../AuthContext';
import { useNavigate } from 'react-router-dom';
import './_authentication_page.scss';

function AuthenticationPage(props) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [database, setDatabase] = useState(localStorage.getItem('selectedDatabase') || 'main');
    const [error, setError] = useState(null);

    const { login } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.setItem('selectedDatabase', database);
    }, [database]);

    const handleLogin = async () => {
        try {
            await login(username, password, database === 'test');
            // Redirect to the dashboard or home page
            navigate('/');
        } catch (err) {
            if (err.message === 'Failed to authenticate.') {
                setError("Uporabniško ime ali geslo je nepravilno.");
            } else {
                // generic message for other types of errors
                setError("Prišlo je do napake. Prosimo, poskusite znova.");
            }
        }
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();  // Prevents the default form submission behavior
        handleLogin();
    };

    const databaseOptions = [
        { id: 'main', label: 'Glavna' },
        { id: 'test', label: 'Testna' }
    ];

    return (
        <div className="auth-container">
            <img
                src={process.env.PUBLIC_URL + '/agentron-wordmark-2.png'}
                alt="Agentron"
                className="auth-logo" // Add a class for targeted styling, if needed.
            />
            <h2>Prijava</h2>
            <br />
            <form onSubmit={handleFormSubmit}>
                <TextInput
                    id="username"
                    labelText="Uporabniško ime"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <TextInput
                    id="password"
                    labelText="Geslo"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />

                <Button type="submit" className="centered-text custom-button">Prijava</Button>
                <br />
                <br />
                <Dropdown
                    id="database"
                    titleText="Izberite bazo podatkov"
                    label="Izberite bazo podatkov"
                    items={databaseOptions}
                    itemToString={(item) => (item ? item.label : '')}
                    selectedItem={databaseOptions.find(option => option.id === database)}
                    onChange={({ selectedItem }) => setDatabase(selectedItem.id)}
                />
            </form>

            {error && (
                <div className="notification">
                    <InlineNotification
                        kind="error"
                        title="Napaka pri prijavi"
                        subtitle={error}
                    />
                </div>
            )}
        </div>
    );
}

export default AuthenticationPage;