// MapComponent.js
import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import proj4 from 'proj4';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import transformCoordinates from './transformCoordiantes';

// Define projections
proj4.defs(
  "EPSG:3794",
  "+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9999 " +
  "+x_0=500000 +y_0=-5000000 +ellps=GRS80 " +
  "+towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);

const defaultIcon = L.icon({
  iconUrl: require('../../themes/icons/marker-icon2.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const FitBounds = ({ positions, defaultCenter, defaultZoom }) => {
  const map = useMap();

  useEffect(() => {
    if (positions.length === 0) {
      map.setView(defaultCenter, defaultZoom);
    } else if (positions.length === 1) {
      map.setView(positions[0], defaultZoom);
    } else {
      const bounds = L.latLngBounds(positions);
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [positions, map, defaultCenter, defaultZoom]);

  return null;
};

const MapComponent = ({ data }) => {
  const positions = data
    .filter(row => row.geometry)
    .map(row => transformCoordinates(row.geometry));

  const defaultCenter = [46.0569, 14.5058];
  const defaultZoom = 8;

  return (
    <MapContainer
      center={defaultCenter} 
      zoom={defaultZoom}
      scrollWheelZoom={true}
      touchZoom={true}
      doubleClickZoom={true}
      zoomControl={true}
      dragging={true}
      style={{ height: "100vh", width: "100%" }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {positions.map((position, idx) => {
        const uniqueKey = data[idx]?.id || idx;
        return (
          <Marker 
            key={uniqueKey} 
            position={position} 
            icon={defaultIcon} 
          />
        );
      })}
      <FitBounds 
        positions={positions} 
        defaultCenter={defaultCenter} 
        defaultZoom={defaultZoom} 
      />
    </MapContainer>
  );
};

export default MapComponent;