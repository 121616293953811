import React from 'react';
import { Link, Route, Routes, Navigate } from 'react-router-dom';
import {
  Content,
  Header,
  HeaderName,
  HeaderGlobalBar,
  HeaderNavigation,
  HeaderMenuItem,
  Grid,
  Column,
  Theme,
} from '@carbon/react';
import AkcijeAdmin from './AkcijeAdmin';
import PaketiAdmin from './PaketiAdmin';
import ObiskiAdmin from './ObiskiAdmin';
import AgentLeaderboard from './AgentLeaderboard';
import UsersAdmin from './UsersAdmin';
import './CSS/Admin.css';
import VsaNarocila from './OrdersAdmin';
import TerenImport from './TerenImport';
import { useAuth } from '../../AuthContext';
import AdminAnalytics from './AdminAnalytics';
import AnalitkaAgentov from './AnalitikaAgentov';


const AdminPage = () => {

  const { logout, isTestDatabase, currentUserRole } = useAuth();

  return (

    <div className="admin-page">
      <Theme theme="g100">
        <Header aria-label="Agentron Admin">
          <HeaderName href="/" prefix="Agentron">
            Admin
          </HeaderName>

          <HeaderNavigation aria-label="Admin Navigacija">
            <HeaderMenuItem element={Link} to="/admin-dash/users">
              Uporabniki
            </HeaderMenuItem>
            <HeaderMenuItem element={Link} to="/admin-dash/leaderboard">
              Lestvica
            </HeaderMenuItem>
            <HeaderMenuItem element={Link} to="/admin-dash/obiski">
              Obiski
            </HeaderMenuItem>
            <HeaderMenuItem element={Link} to="/admin-dash/analytics">
              Analitika
            </HeaderMenuItem>
            <HeaderMenuItem as={Link} to="/admin-dash/narocila">
              Naročila
            </HeaderMenuItem>
            <HeaderMenuItem element={Link} to="/admin-dash/actions">
              Akcije
            </HeaderMenuItem>
            <HeaderMenuItem element={Link} to="/admin-dash/packages">
              Paketi
            </HeaderMenuItem>
            <HeaderMenuItem element={Link} to="/admin-dash/importplan">
              Uvoz terena
            </HeaderMenuItem>
          </HeaderNavigation>

          <HeaderGlobalBar>
            {isTestDatabase && (
              <div className="test-database-indicator">Testna baza!</div>
            )}
          </HeaderGlobalBar>
        </Header>

        <Content className="main-content">
          <Grid fullWidth>
            <Column lg={16} md={8} sm={4}>
              <Routes>
                <Route path="/" element={<Navigate to="/admin-dash/users" />} />
                <Route path="users" element={<UsersAdmin />} />
                <Route path="leaderboard" element={<AgentLeaderboard />} />
                <Route path="obiski" element={<ObiskiAdmin />} />
                <Route path="analytics" element={<>
                  <AnalitkaAgentov />
                  {/* <AdminAnalytics /> */}
                </>} />
                <Route path="actions" element={<AkcijeAdmin />} />
                <Route path="packages" element={<PaketiAdmin />} />
                <Route path="narocila" element={<VsaNarocila />} />
                <Route path="importplan" element={<TerenImport />} />
              </Routes>
            </Column>
          </Grid>
        </Content>
      </Theme>
    </div>
  );
};

export default AdminPage;