import React, { useEffect, useState, useMemo, useRef } from 'react';
import PocketBase from 'pocketbase';
import {
    Grid,
    Column,
    Select,
    SelectItem,
    Button,
    ProgressBar,
    Accordion,
    AccordionItem,
    Tile,
    DataTable,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
} from '@carbon/react';
import { DonutChart, GaugeChart } from '@carbon/charts-react';
import '@carbon/charts/styles.css';
import './CSS/AnalitikaAgentov.css';
import { useAuth } from '../../AuthContext';

const AnalitikaAgentov = () => {
    const { isAuthenticated, currentUserId, currentUserFullName, currentUserPhoneNum, currentUserEmail, pb, login, logout, isTestDatabase } = useAuth();

    const [agents, setAgents] = useState([]);
    const [visits, setVisits] = useState([]);
    const [orders, setOrders] = useState([]);
    const [plans, setPlans] = useState([]);
    const [planTeren, setPlanTeren] = useState([]);
    const [loading, setLoading] = useState(true);
    const [progress, setProgress] = useState(0); 
    const [error, setError] = useState(null);

    const [inputMonth, setInputMonth] = useState('');
    const [inputYear, setInputYear] = useState('');
    const [inputAgent, setInputAgent] = useState('');

    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedAgent, setSelectedAgent] = useState(null);

    const [leads, setLeads] = useState([]);
    const [leadsCount, setLeadsCount] = useState(0);
    const [topPackages, setTopPackages] = useState([]);

    const progressInterval = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                setProgress(0); 

                progressInterval.current = setInterval(() => {
                    setProgress((prev) => {
                        if (prev < 99) {
                            return prev + 1;
                        } else {
                            clearInterval(progressInterval.current);
                            return prev;
                        }
                    });
                }, 125);

                const totalFetches = 7; 

                // Fetch agents
                const agentsList = await pb.collection('users').getFullList({
                    sort: 'name',
                    fields: 'id,name',
                });
                setAgents(agentsList);

                // Fetch visits
                const visitsList = await pb.collection('obiski').getFullList({
                    sort: '-created',
                    fields: 'agent,odziv,teren,created',
                });
                setVisits(visitsList);

                // Fetch orders
                const ordersList = await pb.collection('narocilo').getFullList({
                    sort: '-created',
                    fields: 'created,sentDate,agent,paket',
                });
                setOrders(ordersList);

                // Fetch plans
                const plansList = await pb.collection('plan').getFullList({
                    sort: '-created',
                    fields: 'id,ime,agent',
                });
                setPlans(plansList);

                // Fetch plan_teren
                const planTerenList = await pb.collection('plan_teren').getFullList({
                    sort: '-created',
                    fields: 'plan,teren',
                });
                setPlanTeren(planTerenList);

                // Fetch leads
                const leadsList = await pb.collection('stranke').getFullList({
                    filter: 'is_lead=true',
                    sort: '-created',
                    fields: 'id,ime,priimek,agent',
                });
                setLeads(leadsList);
                setLeadsCount(leadsList.length);

                // Fetch records for top packages
                const records = await pb.collection('narocilo').getFullList({
                    fields: 'paket',
                });

                const packageCount = {};
                records.forEach(record => {
                    if (record.paket) {
                        packageCount[record.paket] = (packageCount[record.paket] || 0) + 1;
                    }
                });

                const topPackages = Object.entries(packageCount)
                    .sort(([, countA], [, countB]) => countB - countA)
                    .slice(0, 5)
                    .map(([paket, count]) => ({ paket, count }));

                setTopPackages(topPackages);

                clearInterval(progressInterval.current);
                setProgress(100);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching data:', err);
                setError('Pridobivanje podatkov ni uspelo. Prosimo, poskusite kasneje.');
                clearInterval(progressInterval.current);
                setLoading(false);
            }
        };

        fetchData();

        return () => {
            if (progressInterval.current) {
                clearInterval(progressInterval.current);
            }
        };
    }, []);

    // Time difference in minutes
    const calculateTimeDifferenceInMinutes = (start, end) => {
        const startDate = new Date(start);
        const endDate = new Date(end);
        const diffInMs = endDate - startDate;
        return diffInMs / (1000 * 60);
    };

    // Get date range
    const getDateRange = (month, year) => {
        if (!month || !year) return null;

        const startDate = new Date(year, month - 1, 1, 0, 0, 0);
        const endDate = new Date(year, month, 0, 23, 59, 59);

        const formatDate = (date) => {
            const yyyy = date.getFullYear();
            const mm = String(date.getMonth() + 1).padStart(2, '0');
            const dd = String(date.getDate()).padStart(2, '0');
            const hh = String(date.getHours()).padStart(2, '0');
            const min = String(date.getMinutes()).padStart(2, '0');
            const ss = String(date.getSeconds()).padStart(2, '0');
            return `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
        };

        return {
            startDate: formatDate(startDate),
            endDate: formatDate(endDate),
        };
    };

    // Filtered visits
    const filteredVisits = useMemo(() => {
        let filtered = [...visits];
        const dateRange = getDateRange(selectedMonth, selectedYear);

        if (dateRange) {
            filtered = filtered.filter((visit) => {
                const visitDate = new Date(visit.created);
                return (
                    visitDate >= new Date(dateRange.startDate) &&
                    visitDate <= new Date(dateRange.endDate)
                );
            });
        }

        if (selectedAgent) {
            filtered = filtered.filter((visit) => visit.agent === selectedAgent);
        }

        return filtered;
    }, [visits, selectedMonth, selectedYear, selectedAgent]);

    // Filtered orders
    const filteredOrders = useMemo(() => {
        let filtered = [...orders];
        const dateRange = getDateRange(selectedMonth, selectedYear);

        if (dateRange) {
            filtered = filtered.filter((order) => {
                const orderDate = new Date(order.created);
                return (
                    orderDate >= new Date(dateRange.startDate) &&
                    orderDate <= new Date(dateRange.endDate)
                );
            });
        }

        if (selectedAgent) {
            filtered = filtered.filter((order) => order.agent === selectedAgent);
        }

        return filtered;
    }, [orders, selectedMonth, selectedYear, selectedAgent]);

    // Sales counts
    const filteredTotalSales = useMemo(() => filteredOrders.length, [filteredOrders]);
    const overallTotalSales = useMemo(() => orders.length, [orders]);

    // Agent data
    const getAgentData = (agentId) => {
        const agentVisits = filteredVisits.filter((visit) => visit.agent === agentId);
        const odzivCounts = agentVisits.reduce((acc, visit) => {
            const odziv = visit.odziv || 'Ni specificirano';
            acc[odziv] = (acc[odziv] || 0) + 1;
            return acc;
        }, {});

        const agentOrders = filteredOrders.filter((order) => order.agent === agentId);
        const timeDifferences = agentOrders
            .filter((order) => order.created && order.sentDate)
            .map((order) => calculateTimeDifferenceInMinutes(order.created, order.sentDate));

        let avgSellTime = 'Ni na voljo';
        if (timeDifferences.length > 0) {
            const totalTime = timeDifferences.reduce((acc, time) => acc + time, 0);
            avgSellTime = (totalTime / timeDifferences.length).toFixed(2);
        }

        const agentSalesCount = agentOrders.length;

        const chartData = Object.entries(odzivCounts).map(([odziv, count]) => ({
            group: odziv,
            value: count,
        }));

        const agentPlans = plans.filter((plan) => plan.agent === agentId);
        const visitedTerrens = new Set(agentVisits.map((v) => v.teren));

        const plansData = agentPlans.map((plan) => {
            const linkedPlanTerens = planTeren.filter((pt) => pt.plan === plan.id);
            const totalAddresses = linkedPlanTerens.length;
            const visitedAddresses = linkedPlanTerens.filter((pt) => visitedTerrens.has(pt.teren)).length;
            const progressPercentage = totalAddresses > 0 ? (visitedAddresses / totalAddresses) * 100 : 0;

            return {
                id: plan.id,
                planName: plan.ime || 'Ni na voljo',
                totalAddresses,
                visitedAddresses,
                progressPercentage: progressPercentage.toFixed(2),
            };
        });

        const totalAddresses = plansData.reduce((acc, plan) => acc + parseInt(plan.totalAddresses, 10), 0);
        const totalVisitedAddresses = plansData.reduce((acc, plan) => acc + parseInt(plan.visitedAddresses, 10), 0);
        const totalProgressPercentage = totalAddresses > 0 ? (totalVisitedAddresses / totalAddresses) * 100 : 0;

        const packageCount = {};
        agentOrders.forEach(order => {
            if (order.paket) {
                packageCount[order.paket] = (packageCount[order.paket] || 0) + 1;
            }
        });

        const topPackages = Object.entries(packageCount)
            .sort(([, countA], [, countB]) => countB - countA)
            .slice(0, 5)
            .map(([paket, count]) => ({ paket, count }));

        const agentLeadsCount = leads.filter(lead => lead.agent === agentId).length;

        return {
            chartData,
            avgSellTime,
            plansData,
            totalAddresses,
            totalVisitedAddresses,
            totalProgressPercentage,
            agentSalesCount,
            topPackages,
            agentLeadsCount,
        };
    };

    // Overall data
    const overallData = useMemo(() => {
        const totalVisits = visits.length;

        const odzivCounts = visits.reduce((acc, visit) => {
            const odziv = visit.odziv || 'Ni specificirano';
            acc[odziv] = (acc[odziv] || 0) + 1;
            return acc;
        }, {});

        const totalOrders = orders.length;

        const timeDifferences = orders
            .filter(order => order.created && order.sentDate)
            .map(order => calculateTimeDifferenceInMinutes(order.created, order.sentDate));

        let avgSellTime = 'Ni na voljo';
        if (timeDifferences.length > 0) {
            const totalTime = timeDifferences.reduce((acc, time) => acc + time, 0);
            avgSellTime = (totalTime / timeDifferences.length).toFixed(2);
        }

        const visitedTerrens = new Set(visits.map(v => v.teren));

        const plansData = plans.map(plan => {
            const linkedPlanTerens = planTeren.filter(pt => pt.plan === plan.id);
            const totalAddresses = linkedPlanTerens.length;
            const visitedAddresses = linkedPlanTerens.filter(pt => visitedTerrens.has(pt.teren)).length;
            const progressPercentage = totalAddresses > 0 ? (visitedAddresses / totalAddresses) * 100 : 0;

            return {
                id: plan.id,
                planName: plan.ime || 'Ni na voljo',
                totalAddresses,
                visitedAddresses,
                progressPercentage: progressPercentage.toFixed(2),
            };
        });

        const totalAddresses = plansData.reduce((acc, plan) => acc + parseInt(plan.totalAddresses, 10), 0);
        const totalVisitedAddresses = plansData.reduce((acc, plan) => acc + parseInt(plan.visitedAddresses, 10), 0);
        const totalProgressPercentage = totalAddresses > 0 ? (totalVisitedAddresses / totalAddresses) * 100 : 0;

        return {
            chartData: Object.entries(odzivCounts).map(([odziv, count]) => ({
                group: odziv,
                value: count,
            })),
            avgSellTime,
            plansData,
            totalAddresses,
            totalVisitedAddresses,
            totalProgressPercentage,
            totalSales: totalOrders,
            topPackages,
            leadsCount,
        };
    }, [visits, orders, plans, planTeren, topPackages, leadsCount]);

    // Filter handlers
    const handleFilter = () => {
        setSelectedMonth(inputMonth ? parseInt(inputMonth) : null);
        setSelectedYear(inputYear ? parseInt(inputYear) : null);
        setSelectedAgent(inputAgent || null);
    };

    const handleReset = () => {
        setInputMonth('');
        setInputYear('');
        setInputAgent('');
        setSelectedMonth(null);
        setSelectedYear(null);
        setSelectedAgent(null);
    };

    if (loading) {
        return (
            <div className="loading-container">
                <h2>Nalaganje podatkov...</h2>
                <ProgressBar
                    label="Nalaganje"
                    helperText={`Nalaganje: ${Math.round(progress)}%`}
                    value={progress}
                    size="md"
                />
            </div>
        );
    }

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    return (
        <div className="analitika-container">
            <h1>Analitika Agentov</h1>

            <div className="overall-total-sales">
                <span>Skupna Prodaja: {overallTotalSales}</span>
            </div>

            <Grid className="filter-section" fullWidth>
                <Column sm={4} md={4} lg={4}>
                    <Select
                        id="select-month"
                        labelText="Izberi mesec"
                        value={inputMonth}
                        onChange={(e) => setInputMonth(e.target.value)}
                    >
                        <SelectItem disabled hidden value="" text="Vsi meseci" />
                        {[
                            { value: 1, label: 'Januar' },
                            { value: 2, label: 'Februar' },
                            { value: 3, label: 'Marec' },
                            { value: 4, label: 'April' },
                            { value: 5, label: 'Maj' },
                            { value: 6, label: 'Junij' },
                            { value: 7, label: 'Julij' },
                            { value: 8, label: 'Avgust' },
                            { value: 9, label: 'September' },
                            { value: 10, label: 'Oktober' },
                            { value: 11, label: 'November' },
                            { value: 12, label: 'December' },
                        ].map((month) => (
                            <SelectItem key={month.value} value={month.value} text={month.label} />
                        ))}
                    </Select>
                </Column>

                <Column sm={4} md={4} lg={4}>
                    <Select
                        id="select-year"
                        labelText="Izberi leto"
                        value={inputYear}
                        onChange={(e) => setInputYear(e.target.value)}
                    >
                        <SelectItem disabled hidden value="" text="Vsa leta" />
                        {Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - 5 + i).map((year) => (
                            <SelectItem key={year} value={year} text={year} />
                        ))}
                    </Select>
                </Column>

                <Column sm={4} md={4} lg={4}>
                    <Select
                        id="select-agent"
                        labelText="Izberi agenta"
                        value={inputAgent}
                        onChange={(e) => setInputAgent(e.target.value)}
                    >
                        <SelectItem disabled hidden value="" text="Vsi agenti" />
                        {agents.map((agent) => (
                            <SelectItem key={agent.id} value={agent.id} text={agent.name} />
                        ))}
                    </Select>
                </Column>

                <Column sm={4} md={4} lg={4} className="filter-buttons">
                    <Button kind="primary" onClick={handleFilter}>
                        Filtriraj
                    </Button>
                    <Button kind="secondary" onClick={handleReset}>
                        Ponastavi
                    </Button>
                </Column>
            </Grid>

            {!selectedMonth && !selectedYear && !selectedAgent ? (
                <Column sm={16} md={16} lg={16} className="overall-section">
                    <section>
                        <h2>Skupne Statistike</h2>

                        {overallData.totalAddresses > 0 && (
                            <div className="full-width-progress">
                                <ProgressBar
                                    label="Skupni napredek obiskov"
                                    helperText={`${overallData.totalVisitedAddresses} od ${overallData.totalAddresses} naslovov obiskanih`}
                                    value={overallData.totalProgressPercentage}
                                    size="md"
                                />
                            </div>
                        )}

                        <Grid fullWidth style={{ marginTop: '30px', gap: '20px' }}>
                            <Column sm={16} md={5} lg={5}>
                                <div className="dashboard-chart">
                                    <h3 className="text-white margin-bottom-10">Analiza odzivov strank</h3>
                                    <Tile className="tile-custom">
                                        <Accordion allowZeroExpanded>
                                            {overallData.chartData.map((item) => (
                                                <AccordionItem key={item.group} title={item.group}>
                                                    <p><strong>Število:</strong> {item.value}</p>
                                                    <p><strong>Odstotek:</strong> {overallData.totalAddresses > 0 ? ((item.value / overallData.totalAddresses) * 100).toFixed(2) : '0.00'}%</p>
                                                </AccordionItem>
                                            ))}
                                        </Accordion>
                                    </Tile>
                                </div>
                            </Column>

                            <Column sm={16} md={6} lg={6}>
                                <div className="dashboard-chart">
                                    <h3 className="text-white margin-bottom-10">Vizualizacija odzivov</h3>
                                    {overallData.chartData.length > 0 ? (
                                        <DonutChart
                                            data={overallData.chartData}
                                            options={{
                                                title: 'Odzivi obiskov',
                                                height: '300px',
                                                resizable: true,
                                                donut: {
                                                    center: {
                                                        label: `${overallData.chartData.reduce((sum, item) => sum + item.value, 0)} Odzivi`,
                                                    },
                                                },
                                                legend: {
                                                    alignment: 'center',
                                                },
                                                pie: {
                                                    label: {
                                                        alignment: 'center',
                                                        position: 'outside',
                                                        numberFontSize: '14px',
                                                    },
                                                },
                                            }}
                                        />
                                    ) : (
                                        <div className="text-white">Podatkov ni na voljo</div>
                                    )}
                                </div>
                            </Column>

                            <Column sm={16} md={5} lg={5}>
                                <div className="dashboard-chart">
                                    <h3 className="text-white margin-bottom-10">Delež prodaje vseh agentov</h3>
                                    <GaugeChart
                                        data={[
                                            {
                                                group: 'value',
                                                value: overallData.totalSales > 0 ? 100 : 0,
                                            },
                                        ]}
                                        options={{
                                            title: 'Delež prodaje vseh agentov',
                                            resizable: true,
                                            height: '250px',
                                            gauge: {
                                                type: 'semi',
                                                status: 'success',
                                            },
                                        }}
                                    />
                                </div>
                            </Column>
                        </Grid>

                        <Grid fullWidth style={{ marginTop: '40px', gap: '20px' }}>
                            <Column sm={16} md={8} lg={8}>
                                <Tile className="tile-custom">
                                    <h3>Najbolj prodajani paketi</h3>
                                    {overallData.topPackages.length > 0 ? (
                                        <DataTable
                                            rows={overallData.topPackages.map((pkg, index) => ({
                                                id: index + 1,
                                                package: pkg.paket,
                                                count: pkg.count,
                                            }))}
                                            headers={[
                                                { key: 'id', header: '' },
                                                { key: 'package', header: 'Paket' },
                                                { key: 'count', header: 'Število naročil' },
                                            ]}
                                        >
                                            {({ rows, headers, getHeaderProps, getRowProps }) => (
                                                <TableContainer>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow className="table-header">
                                                                {headers.map(header => (
                                                                    <TableHeader
                                                                        {...getHeaderProps({ header })}
                                                                        key={header.key}
                                                                        style={{ backgroundColor: '#161616', color: '#fff' }}
                                                                    >
                                                                        {header.header}
                                                                    </TableHeader>
                                                                ))}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody className="table-cell">
                                                            {rows.map(row => (
                                                                <TableRow {...getRowProps({ row })} key={row.id} style={{ backgroundColor: '#161616' }}>
                                                                    {row.cells.map(cell => (
                                                                        <TableCell key={cell.id} style={{ color: '#fff' }}>
                                                                            {cell.value}
                                                                        </TableCell>
                                                                    ))}
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            )}
                                        </DataTable>
                                    ) : (
                                        <p>Ni prodanih paketov.</p>
                                    )}
                                </Tile>
                            </Column>

                            <Column sm={16} md={4} lg={4}>
                                <Tile className="tile-custom text-center">
                                    <h3>Število Interesantov</h3>
                                    <h2>{overallData.leadsCount}</h2>
                                </Tile>
                            </Column>

                            <Column sm={16} md={4} lg={4}>
                                <Tile className="tile-custom text-center">
                                    <h3>Povprečni Čas Prodaje</h3>
                                    {overallData.avgSellTime !== 'Ni na voljo' ? (
                                        <h2>{overallData.avgSellTime} min</h2>
                                    ) : (
                                        <p>Ni na voljo</p>
                                    )}
                                </Tile>
                            </Column>
                        </Grid>
                    </section>
                </Column>
            ) : (
                <Grid fullWidth>
                    {agents
                        .filter((agent) => (selectedAgent ? agent.id === selectedAgent : true))
                        .map((agent) => {
                            const {
                                chartData,
                                avgSellTime,
                                plansData,
                                totalAddresses,
                                totalVisitedAddresses,
                                totalProgressPercentage,
                                agentSalesCount,
                                topPackages,
                                agentLeadsCount,
                            } = getAgentData(agent.id);

                            const agentSalesPercentage = overallTotalSales > 0
                                ? ((agentSalesCount / overallTotalSales) * 100).toFixed(2)
                                : '0.00';

                            const odzivCounts = chartData.reduce((acc, item) => {
                                acc[item.group] = item.value;
                                return acc;
                            }, {});

                            const odzivPercentages = Object.keys(odzivCounts).reduce((acc, odziv) => {
                                acc[odziv] = totalAddresses > 0 ? (odzivCounts[odziv] / totalAddresses) * 100 : 0;
                                return acc;
                            }, {});

                            const donutData = chartData.map(item => ({
                                group: item.group,
                                value: item.value,
                            }));

                            const donutOptions = {
                                title: 'Odzivi obiskov',
                                height: '300px',
                                resizable: true,
                                donut: {
                                    center: {
                                        label: `${chartData.reduce(
                                            (sum, item) => sum + item.value,
                                            0
                                        )} Odzivi`,
                                    },
                                },
                                legend: {
                                    alignment: 'center',
                                },
                                pie: {
                                    label: {
                                        alignment: 'center',
                                        position: 'outside',
                                        numberFontSize: '14px',
                                    },
                                },
                            };

                            return (
                                <Column sm={16} md={16} lg={16} key={agent.id} className="agent-section">
                                    <section>
                                        <h2>{agent.name}</h2>

                                        {totalAddresses > 0 && (
                                            <div className="full-width-progress">
                                                <ProgressBar
                                                    label="Skupni napredek obiskov"
                                                    helperText={`${totalVisitedAddresses} od ${totalAddresses} naslovov obiskanih`}
                                                    value={totalProgressPercentage}
                                                    size="md"
                                                />
                                            </div>
                                        )}

                                        {plansData.length > 0 && (
                                            <div className="progress-bars-container">
                                                {plansData.map((plan) => (
                                                    <div key={plan.id} className="progress-bar-item">
                                                        <h4>{plan.planName}</h4>
                                                        <ProgressBar
                                                            label={`Napredek odzivov za ${plan.planName}`}
                                                            helperText={`${plan.visitedAddresses} od ${plan.totalAddresses} naslovov obiskanih`}
                                                            value={
                                                                plan.totalAddresses > 0
                                                                    ? (plan.visitedAddresses / plan.totalAddresses) * 100
                                                                    : 0
                                                            }
                                                            size="md"
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                        <Grid fullWidth style={{ marginTop: '30px', gap: '20px' }}>
                                            <Column sm={16} md={5} lg={5}>
                                                <div className="dashboard-chart">
                                                    <h3 className="text-white margin-bottom-10">Analiza odzivov strank</h3>
                                                    <Tile className="tile-custom">
                                                        <Accordion allowZeroExpanded>
                                                            {Object.keys(odzivCounts).map((odziv) => (
                                                                <AccordionItem key={odziv} title={odziv}>
                                                                    <p><strong>Število:</strong> {odzivCounts[odziv]}</p>
                                                                    <p><strong>Odstotek:</strong> {odzivPercentages[odziv].toFixed(2)}%</p>
                                                                </AccordionItem>
                                                            ))}
                                                        </Accordion>
                                                    </Tile>
                                                </div>
                                            </Column>

                                            <Column sm={16} md={6} lg={6}>
                                                <div className="dashboard-chart">
                                                    <h3 className="text-white margin-bottom-10">Vizualizacija odzivov</h3>
                                                    {donutData.length > 0 ? (
                                                        <DonutChart
                                                            data={donutData}
                                                            options={donutOptions}
                                                        />
                                                    ) : (
                                                        <div className="text-white">Podatkov ni na voljo</div>
                                                    )}
                                                </div>
                                            </Column>

                                            <Column sm={16} md={5} lg={5}>
                                                <div className="dashboard-chart">
                                                    <h3 className="text-white margin-bottom-10">Delež prodaje agenta</h3>
                                                    <p>{agentSalesCount} naročil</p>
                                                    <GaugeChart
                                                        data={[
                                                            {
                                                                group: 'value',
                                                                value: parseFloat(agentSalesPercentage)
                                                            },
                                                        ]}
                                                        options={{
                                                            title: 'Delež prodaje agenta',
                                                            resizable: true,
                                                            height: '250px',
                                                            gauge: {
                                                                type: 'semi',
                                                                status: 'danger'
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Column>
                                        </Grid>

                                        <Grid fullWidth style={{ marginTop: '40px', gap: '20px' }}>
                                            <Column sm={16} md={8} lg={8}>
                                                <Tile className="tile-custom">
                                                    <h3>Najbolj prodajani paketi</h3>
                                                    {topPackages.length > 0 ? (
                                                        <DataTable
                                                            rows={topPackages.map((pkg, index) => ({
                                                                id: index + 1,
                                                                package: pkg.paket,
                                                                count: pkg.count,
                                                            }))}
                                                            headers={[
                                                                { key: 'id', header: '' },
                                                                { key: 'package', header: 'Paket' },
                                                                { key: 'count', header: 'Število naročil' },
                                                            ]}
                                                        >
                                                            {({ rows, headers, getHeaderProps, getRowProps }) => (
                                                                <TableContainer>
                                                                    <Table>
                                                                        <TableHead>
                                                                            <TableRow className="table-header">
                                                                                {headers.map(header => (
                                                                                    <TableHeader
                                                                                        {...getHeaderProps({ header })}
                                                                                        key={header.key}
                                                                                        style={{ backgroundColor: '#161616', color: '#fff' }}
                                                                                    >
                                                                                        {header.header}
                                                                                    </TableHeader>
                                                                                ))}
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody className="table-cell">
                                                                            {rows.map(row => (
                                                                                <TableRow {...getRowProps({ row })} key={row.id} style={{ backgroundColor: '#161616' }}>
                                                                                    {row.cells.map(cell => (
                                                                                        <TableCell key={cell.id} style={{ color: '#fff' }}>
                                                                                            {cell.value}
                                                                                        </TableCell>
                                                                                    ))}
                                                                                </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            )}
                                                        </DataTable>
                                                    ) : (
                                                        <p>Ni prodanih paketov.</p>
                                                    )}
                                                </Tile>
                                            </Column>

                                            <Column sm={16} md={4} lg={4}>
                                                <Tile className="tile-custom text-center">
                                                    <h3>Število Interesantov</h3>
                                                    <h2>{agentLeadsCount}</h2>
                                                </Tile>
                                            </Column>

                                            <Column sm={16} md={4} lg={4}>
                                                <Tile className="tile-custom text-center">
                                                    <h3>Povprečni Čas Prodaje</h3>
                                                    {avgSellTime !== 'Ni na voljo' ? (
                                                        <h2>{avgSellTime} min</h2>
                                                    ) : (
                                                        <p>Ni na voljo</p>
                                                    )}
                                                </Tile>
                                            </Column>
                                        </Grid>
                                    </section>
                                </Column>
                            );
                        })}
                </Grid>
            )}
        </div>
    );
};

export default AnalitikaAgentov;