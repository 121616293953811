import './_sale-page.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import createObiskiRecord from '../../components/TerenTable/createObiskiRecord';
import { useAuth } from '../../AuthContext';
import { TabPanels, ToastNotification, Button } from '@carbon/react';
import { Add, CheckmarkFilled } from '@carbon/icons-react';
import OrderForm from './OrderForm';

import React, { useRef, useEffect, useState } from 'react';
import {
  Form,
  TextInput,
  FormGroup,
  Tabs,
  Tab,
  TabPanel,
  TabList,
} from '@carbon/react';

const SalePage = ({ terenData }) => {
  const [currentForm, setCurrentForm] = useState(0); // State to track the current form

  const handleTabChange = (evt) => {
    setCurrentForm(evt.selectedIndex);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const initialData = location.state?.initialData || {}; // Ensure initialData has a default value

  const [obiskiId, setObiskiId] = useState(''); // id of the obiski record
  console.log("SalePage initial data: ", initialData);

  const { isAuthenticated, currentUserId, currentUserFullName, pb, login, logout } = useAuth();

  const [showToast, setShowToast] = useState(false);

  // Declare state variables for all input fields
  //const [address, setAddress] = useState('');
  //const [postalCode, setPostalCode] = useState('');
  //const [city, setCity] = useState('');
  const [floorApartment, setFloorApartment] = useState(''); // nadstropje_in_st_stanovanja
  const [firstName, setFirstName] = useState(''); // ime
  const [lastName, setLastName] = useState(''); // priimek  
  const [taxNumber, setTaxNumber] = useState(''); // davcna_st
  const [contactPhone, setContactPhone] = useState(''); // kontakt_tek
  const [email, setEmail] = useState(''); // email
  const [notes, setNotes] = useState(''); // opombe
  const [narocila, setNarocila] = useState([]); // narocila
  const [persons, setPersons] = useState([]); // State to store persons data

  const setBasicDataHandler = (data) => {
    setFirstName(data.ime);
    setLastName(data.priimek);
    setTaxNumber(data.davcna_st);
    setContactPhone(data.kontakt_tel);
    setEmail(data.email);
  }

  const fetchPersonsData = async (obiskiData) => {
    try {
      const narociloViaObisk = obiskiData.expand?.narocilo_via_obisk || [];

      const allPersons = narociloViaObisk
        .map(narocilo => narocilo.expand?.stranka)
        .filter(Boolean)
        .reduce((uniquePersons, person) => {
          if (!uniquePersons[person.id]) {
            uniquePersons[person.id] = person;
          }
          return uniquePersons;
        }, {});

      const deduplicatedPersons = Object.values(allPersons);
      setPersons(deduplicatedPersons);
    } catch (error) {
      console.error('Error fetching persons data:', error);
    }
  };

  const fetchObiskiRecord = async () => {
    try {
      const filterString = `teren = "${initialData.id}"`;
      const resultList = await pb.collection('obiski').getList(1, 1, {
        filter: filterString,
        expand: 'narocilo_via_obisk, narocilo_via_obisk.stranka, narocilo_via_obisk.stranka.gdpr, narocilo_via_obisk.tehnologija, narocilo_via_obisk.paket_rel, narocilo_via_obisk.akcija_rel, narocilo_via_obisk.narocilododatnipaketi_via_narocilo, narocilo_via_obisk.narocilododatnipaketi_via_narocilo.paket, narocilo_via_obisk.narocilododatnipaketi_via_narocilo.akcija',
      });

      if (resultList.items.length > 0) {
        const obiskiData = resultList.items[0];
        setObiskiId(obiskiData.id);

        setFirstName(obiskiData.ime || '');
        setLastName(obiskiData.priimek || '');
        setTaxNumber(obiskiData.davcna_st || '');
        setContactPhone(obiskiData.kontakt_tel || '');
        setEmail(obiskiData.email || '');

        setNarocila(obiskiData.expand?.narocilo_via_obisk || []);

        fetchPersonsData(obiskiData);
      } else {
        setNarocila([createDefaultNarocilo()]);
      }
    } catch (error) {
      console.error('Error fetching obiski record:', error);
    }
  };

  const refetchPersonsData = async () => {
    try {
      const filterString = `teren = "${initialData.id}"`;
      const resultList = await pb.collection('obiski').getList(1, 1, {
        filter: filterString,
        expand: 'narocilo_via_obisk, narocilo_via_obisk.stranka',
      });

      if (resultList.items.length > 0) {
        const obiskiData = resultList.items[0];
        fetchPersonsData(obiskiData);
      } else {
        console.warn('No matching obiski record found for refetching persons.');
      }
    } catch (error) {
      console.error('Error refetching persons data:', error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetchPersonsData();
    }, 15000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (initialData.obiski && initialData.obiski.length > 0) {
      setObiskiId(initialData.obiski[0].id);
    }
    if (initialData && initialData.id) {
      fetchObiskiRecord();
      //fetchPersons();
    } // else { // If no initialData, create just a naročilo
    //   setNarocila([createDefaultNarocilo()]);
    // }
    handleSave(false, initialData.obiski ? initialData.obiski[0].id : null);
    if (narocila.length === 0) {
      setNarocila([createDefaultNarocilo()]);
    }
    //if (narocila.length === 0) {
    //  setNarocila([createDefaultNarocilo()]);
    //}
  }, [initialData]);

  useEffect(() => {
    console.log('SalePage persons:', persons);
  }, [persons]);

  const handleCancel = () => {
    navigate('/plan');
  };

  const handleSave = async (shouldShowToast = true, obiskIdData = null) => {
    const data = {
      ...(initialData && initialData.id && { teren: initialData.id }),
      agent: currentUserId,
      odziv: 'Prodano',
      opombe: notes,
      datum: new Date().toISOString(),
    };

    try {
      let obiskiRecord;

      if (initialData && initialData.id) {
        obiskiRecord = await createObiskiRecord(pb, data, obiskIdData || obiskiId);
        setObiskiId(obiskiRecord.data.id);
      } else {
        // Create narocilo directly when no obiski record
        const narociloData = createDefaultNarocilo();
        // Here, you would call the API to create the narocilo
        // const narociloRecord = await pb.collection('narocila').create(narociloData);
        // setNarocila([narociloRecord]);
        console.log('Narocilo created successfully without obiski');
      }

      if (shouldShowToast) {
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 3000);
      }

      console.log('Record created successfully');
    } catch (error) {
      console.error('Error creating record:', error);
    }
  };

  const addTab = () => {
    setNarocila(prevNarocila => [...prevNarocila, createDefaultNarocilo()]);
    setCurrentForm(narocila.length);
  };

  const createDefaultNarocilo = () => {
    const newNarocilo = {
      obisk: obiskiId,
      ulica: initialData?.ulica_naziv || '',
      hisna_st: initialData?.hs_stevilka || '',
      nadstropje_in_st_stanovanja: floorApartment,
      posta: initialData?.postni_okolis_naziv || '',
      postna_st: initialData?.postni_okolis_sifra || '',
      ime: firstName,
      priimek: lastName,
      davcna_st: taxNumber,
      kontakt_tel: contactPhone,
      email: email
    };
    return newNarocilo;
  };

  return (
    <>
      <div className="sale-page-container">
        <Form>
          {false && (
            <>
              <h2 className="section-title">Naslov</h2>
              <FormGroup legendText="">
                <div className="form-row">
                  <div className="form-half-width" style={{ display: 'flex', gap: '20px' }}>
                    <TextInput
                      className="form-quarter-width"
                      labelText="Ulica, hišna številka"
                      id="address"
                      value={`${initialData?.ulica_naziv || ''} ${initialData?.hs_stevilka || ''}`}
                      readOnly
                    />
                    <TextInput
                      className="form-quarter-width"
                      labelText="Nadstropje in št. stanovanja"
                      id="floor-apartment"
                      value={floorApartment}
                      onChange={(e) => setFloorApartment(e.target.value)}
                      placeholder="4/1 ali HIŠA"
                    />
                  </div>
                  <div className="form-half-width" style={{ display: 'flex', gap: '20px' }}>
                    <TextInput
                      className="form-third-width"
                      labelText="Poštna številka"
                      id="postal-code"
                      value={initialData?.postni_okolis_sifra || ''}
                      readOnly
                    />
                    <TextInput
                      className="form-two-thirds-width"
                      labelText="Pošta"
                      id="city"
                      value={initialData?.postni_okolis_naziv || ''}
                      readOnly
                    />
                  </div>
                </div>
              </FormGroup>
            </>
          )}
        </Form>

        <Tabs onChange={handleTabChange} selectedIndex={currentForm}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'inline-block' }}>
              <TabList aria-label="Naročila">
                {narocila.length > 0 ? (
                  narocila.map((narocilo, index) => (
                    <Tab key={narocilo.id || index}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>{`Naročilo ${index + 1}`}</span>
                        {narocilo.sentDate && (
                          <CheckmarkFilled
                            size={16}
                            style={{ marginLeft: '0.5rem', color: 'lightgreen' }}
                            aria-label="Sent"
                          />
                        )}
                      </div>
                    </Tab>
                  ))
                ) : (
                  <Tab>Naročilo 1</Tab>
                )}
              </TabList>
            </div>
            <Button
              kind="ghost"
              renderIcon={Add}
              onClick={addTab}
              style={{ marginLeft: '1rem' }}
            >
              Novo naročilo
            </Button>
          </div>
          <TabPanels>
            {narocila.length > 0 ? (
              narocila.map((narocilo, index) => (
                <TabPanel key={`narocilo-${narocilo.id || index}`}>
                  <OrderForm
                    narociloData={narocilo}
                    setBasicData={setBasicDataHandler}
                    obiskiId={obiskiId}
                    key={`orderForm-${narocilo.id || index}`}
                    persons={persons}
                    setPersons={setPersons}
                  />
                </TabPanel>
              ))
            ) : (
              <TabPanel id="tab-0" label="Narocilo 1">
                <OrderForm
                  setBasicData={setBasicDataHandler}
                  obiskiId={obiskiId}
                  persons={persons}
                  setPersons={setPersons}
                />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </div>
    </>
  );
};

export default SalePage;
